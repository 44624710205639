@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap");

:root {
  --primary-color: rgba(26, 55, 95, 1);
  --secondary-color: rgba(185, 31, 70, 1);
  --white-color: rgba(255, 255, 255, 1);
  --black-color: rgba(0, 0, 0, 1);
  --overlay-color: rgba(26, 55, 95, 0.2);
  --border-P10: 1px solid rgba(26, 55, 95, 0.1);
  --border: 1px solid rgba(26, 55, 95, 0.2);
  --border-primary: 1px solid rgba(26, 55, 95, 1);
  --border-secondary: 1px solid rgba(199, 54, 50, 1);

  /* 8px */
  --font-xxx-sm: 8px;
  /* 10px */
  --font-xx-sm: 10px;
  /* 12px */
  --font-x-sm: 12px;
  /* 14px */
  --font-sm: 14px;
  /* 16px */
  --font-m: 16px;
  /* 32px */
  --font-lg: 32px;

  --0_5x: 2px;
  --1x: 4px;
  --1_5x: 6px;
  --2x: 8px;
  --2_5x: 10px;
  --3x: 12px;
  --3_5x: 14px;
  --4x: 16px;
  --5x: 20px;
  --5_5x: 22px;
  --6x: 24px;
  --7x: 28px;
  --8x: 32px;
  --9x: 36px;
  --10x: 40px;
  --11x: 44px;
  --12x: 48px;
  --13x: 52px;
  --14x: 56px;
  --15x: 60px;
  --16x: 64px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  color: var(--primary-color);
}

p {
  margin-bottom: 0 !important;
}

li {
  list-style: none;
}

button {
  border: none;
  outline: none;
}

input {
  border: none;
  outline: none;
  width: 100%;
}

input::placeholder {
  color: var(--primary-color);
  opacity: 40%;
}

.search-icon {
  height: var(--3x);
  margin-right: var(--3x);
}

.pointer {
  cursor: pointer;
}

.custom-overlay {
  height: 100%;
  width: 100%;
  display: none;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: var(--overlay-color);
}

.loader {
  animation: loadingAnimation 1s linear infinite;
}

.secondary-heading {
  font-size: var(--font-sm);
  font-weight: 600;
  color: var(--primary-color);
  opacity: 60%;
  line-height: var(--4x);
  text-transform: uppercase;
}

.popover-header-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--3x);
}

.popover-heading {
  font-size: var(--font-sm);
  font-weight: 700;
  line-height: var(--4x);
  margin-bottom: 0;
}

.popover-body {
  padding: var(--8x) !important;
}

div.popover.show.bs-popover-auto {
  padding-bottom: 0;
}

.leaflet-container {
  background: none !important;
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background: rgba(26, 55, 95, 0.04);
  border-radius: 0.375rem;
}

::-webkit-scrollbar-thumb {
  background: rgba(26, 55, 95, 0.1);
  border-radius: 0.375rem;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(26, 55, 95, 0.15);
}

@keyframes loadingAnimation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* @media only screen and (max-width: 2560px) {
  :root {
    --font-xxx-sm: 18px;
    --font-xx-sm: 20px;
    --font-x-sm: 22px;
    --font-sm: 24px;
    --font-m: 26px;
    --font-lg: 42px;
  }
} */

@media (min-width: 2001px) {
  :root {
    --font-xxx-sm: 18px;
    --font-xx-sm: 20px;
    --font-x-sm: 22px;
    --font-sm: 24px;
    --font-m: 26px;
    --font-lg: 42px;

    --0_5x: 8px;
    --0_75x: 12px;
    --1x: 16px;
    --1_5x: 18px;
    --2x: 20px;
    --2_5x: 22px;
    --3x: 24px;
    --4x: 28px;
    --4_5x: 30px;
    --5x: 32px;
    --6x: 36px;
    --7x: 40px;
    --8x: 44px;
    --9x: 48px;
    --10x: 52px;
    --11x: 56px;
    --12x: 60px;
    --13x: 64px;
    --14x: 68px;
    --15x: 72px;
    --16x: 76px;
  }
}

@media only screen and (max-width: 2000px) {
  :root {
    /* NORMAL SCREENS */
    --font-xxx-sm: 8px;
    --font-xx-sm: 10px;
    --font-x-sm: 12px;
    --font-sm: 14px;
    --font-m: 16px;
    --font-lg: 32px;
  }
}

@media only screen and (max-width: 768px) {
  :root {
    --font-xxx-sm: 6px;
    --font-xx-sm: 8px;
    --font-x-sm: 10px;
    --font-sm: 12px;
    --font-m: 14px;
    --font-lg: 30px;

    /* --0_5x: 2px; */
    --1x: 2px;
    --1_5x: 4px;
    --2x: 6px;
    --2_5x: 8px;
    --3x: 10px;
    --3_5x: 12px;
    --4x: 14px;
    --5x: 18px;
    --6x: 22px;
    --7x: 26px;
    --8x: 30px;
    --9x: 34px;
    --10x: 38px;
    --11x: 42px;
    --12x: 46px;
    --13x: 50px;
    --14x: 54px;
    --15x: 58px;
    --16x: 62px;
  }

  .display-view-wrapper-responsive {
    flex-basis: 70%;
  }
}
