.headerContainer {
  display: flex;
  align-items: center;
  padding: 0 var(--6x);
  height: 10.369vh;
  border-bottom: var(--border);
}

.headerContainer .ecometerHeadingContainer {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0 var(--4x);
  margin: 0 var(--4x);
  border-left: var(--border);
  gap: var(--4x);
}

.ecometerHeading > h1 {
  font-family: "Libre Baskerville", serif;
  font-size: var(--font-m);
  font-weight: 700;
  margin-bottom: var(--1x);
}

.ecometerHeading > p {
  font-weight: 400;
  font-size: var(--font-x-sm);
  line-height: var(--3_5x);
  opacity: 70%;
}
