.pageContent {
  display: flex;
  padding: 2rem 1.2rem;
}

.loader-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.watermark-wrapper {
  height: 100%;
  background-image: url("../../assets/images/watermark.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.slider-map {
  margin-top: var(--8x);
}
