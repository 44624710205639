.twitter-post {
  width: 1024px;
  height: 1024px;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--10x);
  margin-bottom: 2rem;
  /* visibility: hidden; */
  /* margin-top: "-20rem"; */
}

.twitter-post > .heading_view_wrapper > .chartSectionHeader > h2 {
  font-size: 24px;
  line-height: 32px;
}

.twitter-post > .heading_view_wrapper > #filter-info > .filter-info-text > p {
  opacity: 80%;
  font-size: 18px;
  line-height: 28px;
}

.twitter-post-mid {
  display: flex;
  padding-top: 52px;
  justify-content: space-between;
  align-items: center;
}

.states-box {
  border: 1px solid rgba(178, 24, 66, 0.25);
  border-radius: 12px;
  width: 280px;
  padding-bottom: 10px;
  margin-bottom: 24px;
}

.top-states {
  border: 1px solid rgba(178, 24, 66, 0.25);
}

.bottom-states {
  border: 1px solid rgba(246, 194, 174, 0.7);
}

.states-box > h3 {
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  border-radius: 12px 12px 0 0;
  padding: 10px 16px;
}

.top-states > h3 {
  background: rgba(178, 24, 66, 1);
  color: var(--white-color);
}

.bottom-states > h3 {
  background: rgba(246, 194, 174, 1);
}

.state-details {
  display: flex;
  align-items: center;
  padding: 10px 16px;
}

.state-details-india {
  display: flex;
  align-items: center;
  padding: 10px 7px;
}

.state-sq {
  width: 24px;
  height: 20px;
  border-radius: 4px;
}

/* .top-sq {
  background: rgba(178, 24, 66, 1);
} */

.bottom-sq {
  background: rgba(246, 194, 174, 1);
}

.state-name {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  padding-left: 10px;
}

.state-dot {
  opacity: 60%;
  padding: 10px;
}

.state-value {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
}

.median-avg-box {
  padding-bottom: 0;
}

.median-avg-box > h3 {
  padding: 16px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.median-avg-box > .state-details {
  padding: 24px 16px;
}

.twitter-map {
  height: 100%;
  background-image: url("../../../assets/images/watermark.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 409px;
  position: relative;
  left: 8px;
}

.twitter-heatmap {
  width: 292px;
  height: 85px;
  border: var(--border);
  position: absolute;
  right: 0;
  top: 64px;
}

.twitter-post-bottom > img {
  width: 145px;
}

.twitter-post-bottom > p {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  opacity: 80%;
  margin-top: 8px;
}
