.source-metrices3-card-header {
  background: var(--primary-color);
  border-radius: 12px 12px 0 0;
  display: flex;
  padding: 1rem;
}

.source-icon-wrapper {
  width: 32px;
  height: 32px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  background: rgba(50, 74, 94, 1);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.source-card-icon {
  width: 24px;
  height: 24px;
}

.source-level1 {
  font-size: var(--font-sm);
  font-weight: 600;
  line-height: 14px;
  color: var(--white-color);
}

.source-header-content {
  margin-left: 4%;
  flex-basis: 80%;
}

.level1-content {
  line-height: 19px;
  color: rgba(255, 255, 255, 0.6);
  font-size: var(--font-x-sm);
  font-weight: 400;
}

.source-card > .accordion {
  padding: 18px;
}

.accordion > ul {
  padding: 0;
}

@media only screen and (max-width: 768px) {
  .source-metrices3-card-header {
    padding: 0.7rem;
  }

  .source-card > .accordion {
    padding: 10px 14px;
  }
}

@media (min-width: 2001px) {
  .source-metrices3-card-header {
    border-radius: 20px 20px 0 0;
    padding: 1.5rem;
  }

  .source-icon-wrapper {
    width: 44px;
    height: 44px;
    border-radius: 6px;
  }

  .source-card-icon {
    width: 32px;
    height: 32px;
  }

  .source-level1 {
    line-height: 24px;
  }

  .level1-content {
    line-height: 32px;
  }
}
