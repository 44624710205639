.seperator-line {
  height: 53.456vh;
  position: relative;
  top: 4rem;
  margin: 0 var(--3x);
  border-right: var(--border);
}

.mainContentContainer {
  width: 100%;
  height: 100%;
  height: 83.986vh;
  margin-left: 2%;
}

.mainContentContainer-top {
  width: 100%;
  margin-bottom: 1.5%;
}

.mainContentContainer-mid {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 1.5% 0;
}

_::-webkit-full-page-media,
_:future,
:root .safari_only {
  justify-content: start;
}

_::-webkit-full-page-media,
_:future,
:root .safari_only_right_container_map {
  /* justify-content: start; */
  margin-left: 10.5rem;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 55.3vh;
  margin-right: 0;
  margin-top: 14.632vh;
}

.initial-rightContainer {
  margin-top: 0;
}

.chartSectionHeader-wrapper {
  margin: 1.5% 0;
  width: fit-content;
}

.chartSectionHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.2rem;
}

.heading-icons-wrapper {
  display: flex;
}

.chartSectionHeader h2 {
  font-size: var(--font-m);
  font-weight: 600;
  margin: 0;
  line-height: var(--5x);
}

.inital-visualization-heading,
.compare-visualization-heading {
  max-width: 93%;
}

.chartSectionEdit {
  opacity: 50%;
  margin-left: var(--2x);
}

#filter-info > div {
  display: inline-block;
  font-size: var(--font-x-sm);
  font-weight: 500;
  line-height: var(--4x);
}

#filter-info > div > span {
  color: rgba(217, 217, 217, 1);
  margin: 0 var(--1_5x);
}

#filter-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.filter-info-text {
  width: 75%;

  opacity: 60%;
}

.filter-info-text > p {
  display: inline;
  font-size: var(--font-x-sm);
  font-weight: 500;
  line-height: 14.52px;
  opacity: 60%;
}

.filter-info-text > p > span {
  color: rgba(217, 217, 217, 1);
  margin: 0 var(--1_5x);
}

.mainContentContainer .buttonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--4x);
  margin-top: 3%;
}

.mainContentContainer .buttonsContainer svg {
  width: var(--4x);
  height: var(--4x);
}

.download-container {
  padding-top: var(--4x) !important;
  padding-left: var(--4x) !important;
}

.visualization-wrapper {
  height: fit-content;
  margin-left: var(--3x);
}

.sliderContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}

.btn-data > span {
  font-size: var(--font-x-sm);
  font-weight: 500;
  line-height: var(--4x);
}

.btn-edit-filter-chart {
  font-size: var(--font-xx-sm) !important;
  font-weight: 500 !important;
  line-height: var(--3x) !important;
  color: var(--secondary-color) !important;
  background-color: transparent !important;
  border: var(--border-secondary) !important;
  border-radius: var(--1x) !important;
  margin-left: var(--3x) !important;
  padding: var(--1x) var(--2x) !important;
}

.btn-edit-filter-chart:hover {
  background-color: var(--secondary-color);
  color: var(--white-color);
}

.legend-container {
  height: 12.632vh;
  width: 19.841vw;
  margin-bottom: 2vh;
  border: var(--border);
  border-radius: var(--3x);
  display: flex;
  align-items: center;
  padding: var(--4x);
}

.map-legend-responsive {
  height: 14vh;
}

.bar-legend {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#legend-g-container {
  width: 268px !important;
}

.legend-text {
  margin-top: 1rem !important;
  font-size: 13.8px !important;
  font-weight: 600;
  line-height: 20px !important;
}

.chart-visualization {
  display: flex;
  align-items: stretch;
}

.chart-compare-visualization {
  display: flex;
  align-items: stretch;
}

.map-visualization {
  display: flex;
  align-items: stretch;
}

.bar-visualization {
  display: flex;
  align-items: stretch;
}

.map-compare-visualization {
  display: flex;
  align-items: stretch;
}

.bar-compare-visualization {
  display: flex;
  align-items: stretch;
}

.legend-view-contents .map-legend {
  font-size: 10px;
}

.legend-view-contents {
  width: 20vw !important;
  height: 10vh;
}

.heatmap-heading {
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  color: var(--primary-color);
}

.heatmap-heading > span {
  color: rgba(26, 55, 95, 40%);
}

.heatmap-heading > span.active {
  color: var(--primary-color);
  font-weight: 600;
}

.sorting-heading {
  font-size: var(--font-x-sm);
  line-height: var(--4x);
}

.source-link {
  font-size: var(--font-x-sm);
  font-style: italic;
}

.legend-view-contents-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.display-view-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 2001px) {
  .btn-data {
    border-radius: var(--0_75x);
  }
}
.img-download-logo {
  position: absolute;
  left: 0;
  bottom: var(--2_5x);
  padding-top: 50px;
  
}