.cedaHeaderContainer {
  display: flex;
  align-items: center;
  padding: var(--5x) var(--6x);
  justify-content: space-evenly;
  border-bottom: var(--border-P10);
}

.cedaHeaderMenu {
  display: flex;
  padding: 0 var(--4x);
  margin: 0 var(--4x);
}

.button-header {
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 0.925rem;
  font-weight: 600;
  padding: 0.725rem 1.375rem;
  background-color: white;
  border-radius: 0.5rem;
  cursor: pointer;
}

.toggle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.25rem;
}

.dropdown-content {
  position: absolute;
  font-size: 0.925rem;
  background-color: var(--white-color);
  z-index: 1000;
  padding: var(--2x) var(--2x);
  /* border-radius: var(--3x); */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.dropdown-item {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 0.5rem
  /* margin-top: 0.25rem; */
}

.dropdown-item a {
  display: block;
  padding: 0.5rem;
  text-decoration: none;
  color: var(--primary-color);
}

.dropdown-item a:hover {
  background-color: #f0f0f0;
}

