.metrices3-grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 2.469vw;
  padding: 3% 0;
}

.metrices3-card {
  height: 45.622vh;
  width: 21.164vw;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  border: var(--border-primary);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.metrices3-card-header {
  background: var(--primary-color);
  border-radius: 12px 12px 0 0;
  position: relative;
  min-height: 8.756vh;
}

.icon-wrapper {
  width: 40px;
  height: 40px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  background: rgba(50, 74, 94, 1);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -1.2rem;
  left: 43%;
}

.card-icon {
  width: 20px;
  height: 20px;
}

.level1 {
  color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  font-size: var(--font-m);
  line-height: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.accordion {
  overflow: auto;
  padding: 0 16px 24px 16px;
}

.metrices3-card .accordion h4 {
  font-size: var(--font-sm);
  font-weight: 600;
  opacity: 70%;
  color: var(--primary-color);
  text-transform: uppercase;
  margin: 0 0 0 0.5rem;
  transition: 0.5s;
}

.metrices3-card .accordion h4:hover {
  cursor: pointer;
  opacity: 100%;
  text-decoration: none;
}

.l2-heading-wrapper {
  display: flex;
  align-items: center;
  padding: 1rem 0;
}

.accordion-toggle {
  cursor: pointer;
  opacity: 70%;
}

.accordian-icon {
  height: 1rem;
  width: 1rem;
}

.metrices3-card .accordion h4.selected-accordion-title {
  color: var(--primary-color);
  opacity: 100%;
}

.l3-wrapper {
  display: flex;
  align-items: flex-start;
  margin: 20px 0;
}

.card-badge {
  font-weight: 400;
  font-size: var(--font-x-sm);
  color: rgba(26, 55, 95, 70%);
  background-color: rgba(26, 55, 95, 0.1);
  border-radius: 4px;
  padding: 2px 4px 2px 4px;
  margin-right: 10px;
}

.l3-bullet {
  width: 16px;
  height: 16px;
  margin: 0.25rem 0.5rem 0 0;
}

.metrices3-card .accordion .accordion-content {
  display: none;
}

.metrices3-card .accordion .open-view {
  display: inline;
}

.metrices3-card .accordion .accordion-content ul {
  list-style: none;
  padding-left: 0;
}

.l3-para {
  margin-right: 10px;
  font-size: var(--font-sm);
  font-weight: 400;
  line-height: 19.6px;
  color: var(--primary-color);
  text-decoration: none;
}

.line-separtor {
  border-top: var(--border-primary);
  opacity: 10%;
}

@media only screen and (max-width: 768px) {
  .metrices3-grid {
    grid-template-columns: auto auto auto;
  }

  .metrices3-card {
    width: 28vw;
    margin-bottom: 2rem;
  }

  .metrices3-card-header {
    min-height: auto;
  }

  .icon-wrapper {
    width: 37px;
    height: 37px;
    left: 41%;
  }

  .level1 {
    text-align: center;
    padding: 2rem 0.5rem 0.5rem 0.5rem;
  }

  .accordion {
    padding: 0 12px 20px 12px;
  }

  .accordian-icon {
    height: 0.7rem;
    width: 0.7rem;
  }

  .l2-heading-wrapper {
    padding: 0.7rem 0;
  }

  .l3-wrapper {
    margin: 0.7rem 0;
  }
}

@media (min-width: 2001px) {
  .metrices3-card {
    border-radius: 20px;
  }

  .metrices3-card-header {
    border-radius: 20px 20px 0 0;
  }

  .icon-wrapper {
    width: 52px;
    height: 52px;
    border-radius: 8px;
    top: -1.5rem;
    left: 45%;
  }

  .card-icon {
    width: 32px;
    height: 32px;
  }

  .level1 {
    padding-top: 3.3rem;
  }

  .accordion {
    padding: 0 20px 28px 20px;
  }

  .metrices3-card .accordion h4 {
    margin: 0 0 0 1rem;
  }

  .l2-heading-wrapper {
    padding: 1.3rem 0;
  }

  .accordian-icon {
    height: 1.3rem;
    width: 1.3rem;
  }

  .l3-wrapper {
    margin: 28px 0;
    line-height: 36px;
  }

  .card-badge {
    border-radius: 8px;
    padding: 4px 8px 4px 8px;
  }

  .l3-bullet {
    width: 22px;
    height: 22px;
  }
}
