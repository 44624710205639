.landing-screen {
  width: 92.063vw;
  margin: auto;
}

.hero-section {
  margin: 7% 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.hero-left {
  width: 53.373vw;
}

.hero-content {
  width: 41.204vw;
}

.hero-content > h1 {
  font-size: var(--font-lg);
  font-weight: 800;
  line-height: 38.73px;
}

.hero-content > h1 > span {
  background: -webkit-linear-gradient(#bc2349, #ef616a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-content > p {
  font-size: var(--font-m);
  font-weight: 400;
  line-height: 25.6px;
}

.hero-right {
  width: 26.521vw;
}

.feature-charts {
  margin-top: 6%;
  width: 100%;
}

.feature-charts > h5 {
  margin-bottom: 3%;
  opacity: 80%;
}

.features-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feature-card {
  width: 16.733vw;
  border-radius: 0.5rem;
  background: rgba(26, 55, 95, 0.04);
  border: var(--border);
  position: relative;
  padding: 2.25% 2%;
  text-decoration: none;
}

.feature-card-badge {
  border: var(--border-secondary);
}

.search-entity {
  text-decoration: none;
}

.feature-card > p {
  font-size: var(--font-sm);
  font-weight: 500;
  opacity: 80%;
  line-height: 22.4px;
}

.f-badge {
  position: absolute;
  top: -10px;
  left: 0;
  background: linear-gradient(88.29deg, #ef616a 0%, #c0284c 100%);
  color: var(--white-color);
  font-size: var(--font-xx-sm);
  padding: 0.3% 3.5%;
  border-radius: 0.25rem;
  font-weight: 500;
  line-height: 16px;
}

.cards-category {
  display: flex;
  align-items: center;
}

.cards-category > h4 {
  font-size: var(--font-m);
  font-weight: 600;
  line-height: 19.36px;
  margin-bottom: 0;
}

.cards-filter {
  border-left: var(--border);
  margin-left: 1rem;
  padding-left: 1rem;
}

.cards-filter > span {
  font-size: var(--font-sm);
  font-weight: 500;
  margin-right: 0.5rem;
}

.btn-category {
  width: 3.968vw;
  height: 1.5rem;
  border-radius: 0.5rem;
  margin: 0.3rem;
  font-size: var(--font-x-sm);
  font-weight: 500;
  line-height: 14.52px;
}

.btn-selected {
  background-color: var(--primary-color);
  color: var(--white-color);
  font-weight: 600;
}

.btn-unselected {
  background: rgba(26, 55, 95, 0.1);
  color: var(--primary-color);
}

.search-landing-page {
  position: relative;
  z-index: 1000;
}

.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: var(--border);
  padding: var(--3x) var(--5x);
  margin: var(--7x) 0;
  border-radius: var(--2x);
  font-size: var(--font-sm);
  background-color: var(--white-color);
}

.search-landing-page > .search {
  width: 53.37vw;
}

.search-wrapper {
  flex-basis: 90%;
  display: flex;
  align-items: center;
}

.search-values-wrapper {
  background-color: var(--white-color);
  position: relative;
  bottom: 1.9rem;
  width: 53.37vw;
  max-height: 43.253vh;
  overflow: auto;
  box-shadow: 2px 4px 10px 0px rgba(26, 55, 95, 0.1);
  padding: 1rem;
  border-radius: 0 0 0.5rem 0.5rem;
  border-top: var(--border);
}

.search-heading {
  font-size: var(--font-sm);
  font-weight: 500;
  color: var(--primary-color);
  opacity: 40%;
}

.search-value {
  display: flex;
  align-items: flex-start;
  padding: 0.7rem 0;
  border-bottom: var(--border-P10);
}

.search-items {
  font-size: var(--font-m);
  font-weight: 400;
  line-height: 22px;
}

.search-bullet {
  margin-right: 0.7rem !important;
}

.searchbox-wrapper > input {
  width: 70%;
  margin-right: 1rem;
}

.search-clear {
  font-size: var(--font-sm);
  font-weight: 600;
  line-height: 17px;
  color: rgba(199, 54, 50, 1);
}

.disabled-clear {
  opacity: 50%;
  pointer-events: none;
}

.searchbox-wrapper {
  background-color: var(--white-color);
}

.sub-heading {
  font-size: var(--font-m);
  font-weight: 600;
  line-height: 19.36px;
}

.landing-screen > .line-separtor {
  margin: 4% 0;
}

@media only screen and (max-width: 768px) {
  .hero-left {
    width: 60vw;
  }

  .hero-content {
    width: 60vw;
  }

  .feature-charts > h5 {
    margin-bottom: 5%;
  }

  .feature-card {
    width: 16.733vw;
    width: 19vw;
    padding: 2%;
  }

  .feature-card > p {
    line-height: 19px;
  }

  .cards-category {
    width: 100%;
  }

  .cards-filter {
    margin-left: 0.7rem;
    padding-left: 0.7rem;
  }

  .btn-category {
    width: 6.5vw;
  }

  .search {
    padding: var(--2x) var(--4x);
    width: 60vw !important;
  }

  .search-values-wrapper {
    padding: 0.7rem;
    width: 60vw;
  }

  .search-clear {
    margin-left: 0.5rem;
  }

  .search-value {
    padding: 0.5rem 0;
  }

  .landing-screen > .line-separtor {
    margin-top: 0;
  }
}

@media (min-width: 2001px) {
  .hero-content > p {
    line-height: 40px;
  }

  .feature-card > p {
    line-height: 40px;
  }

  .f-badge {
    top: -14px;
    padding: 2% 3.5%;
  }

  .cards-filter {
    margin-left: 1.5rem;
    padding-left: 1.5rem;
  }

  .btn-category {
    width: 4.2vw;
    height: 2.5rem;
    border-radius: 0.8rem;
    margin: 0.3rem 0.6rem;
  }

  .search {
    padding-left: 1.5rem;
    margin: 2.5rem 0;
    height: 64px;
    border-radius: 12px;
  }

  .search-icon {
    margin-right: 0.7rem;
    height: 20px;
  }

  .search-values-wrapper {
    bottom: 2.8rem;
    padding: 1.2rem;
  }

  .search-value {
    padding: 1.2rem 0;
  }

  .search-bullet {
    margin-right: 1rem !important;
  }

  .landing-screen > .line-separtor {
    margin-top: 2%;
  }
}
